
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import ITab from '@/interfaces/ITab';
import { areRouteQueriesDifferent, routeToDestinationMap } from '@/helpers/routerHelper';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';
import { destinationPageTabs } from '@/models/destinationPageTabs';
import DestinationPageTabTypes from '@/models/destinationPageTabs/DestinationPageTabTypes.enum';

import destinationQuery from '@/graphql/destination/Destination.single.query.gql';

import DetailTabs from '@/components/partials/DetailTabs.vue';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain.vue';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';

@Component({
  name: 'DestinationSinglePage',
  components: {
    MsgEntityNotFound,
    DetailTabs,
    AppDataFilterMain,
    AppHeaderMobile: () => import('@/components/partials/AppHeaderMobile.vue'),
    DestinationDetailsTab: () => import('@/views/destination/DestinationDetailsTab.vue'),
    DestinationMustSeeTab: () => import('@/views/destination/DestinationMustSeeTab.vue'),
    DestinationWhatsHappeningTab: () => import('@/views/destination/DestinationWhatsHappeningTab.vue'),
    DestinationArtSpaceTab: () => import('@/views/destination/DestinationArtSpaceTab.vue'),
    DestinationInsiderTipTab: () => import('@/views/destination/DestinationInsiderTipTab.vue'),
  },
  metaInfo() {
    let title = this.destination?.title || 'Destination';

    if (this.activeTabData?.id !== DestinationPageTabTypes.details) {
      title += `: ${this.activeTabData.title}`;
    }

    return {
      title,
    };
  },
})
export default class DestinationPage extends Vue {
  destination: any = null;
  destinationFetching = true;
  tabTypes = DestinationPageTabTypes;
  activeTab: string = DestinationPageTabTypes.details;
  relatedItems: any[] = [];
  filterGroups: any[] = [];
  filterValues: any = {};
  filterValuesFromRoute: any = {};
  isRouteUpdatedByFilter = false;

  get isAppDataFilterOpened(): boolean {
    return this.$store.state.isAppDataFilterOpened;
  }

  get showAppHeaderFilterBtn(): boolean {
    return this.activeTab === this.tabTypes.mustSee || this.activeTab === this.tabTypes.artSpaces;
  }

  get isMobileScreen(): boolean {
    return this.$store.state.isMobileScreen;
  }

  get isMuseumMemberRole(): boolean {
    return this.$store.state.museumRole;
  }

  get tabs(): ITab[] {
    const tabs: ITab[] = [...destinationPageTabs];

    if (this.destination && this.destination.insiderTips && this.destination.insiderTips.length > 0) {
      const insiderTipsTabIndex = tabs.findIndex((t: ITab) => t.id === this.tabTypes.insiderTips);
      tabs[insiderTipsTabIndex].display = true;
    }

    return tabs.filter((t: ITab) => t.display);
  }

  get activeTabData(): ITab | undefined {
    return destinationPageTabs.find((t) => t.id === this.activeTab);
  }

  get destinationBase() {
    if (!this.destination) {
      return {
        id: null,
        slug: '',
        title: '',
        country: '',
        media: '',
      };
    }
    return {
      id: this.destination.id,
      slug: this.destination.slug,
      title: this.destination.title,
      country: this.destination.country,
      media: this.destination.media?.[0]?.url || '',
    };
  }

  get destinationMapRouteTo() {
    return routeToDestinationMap(this.destination.id, this.destination.slug);
  }

  @Watch('tabs')
  watchTabs(tabs: ITab[]) {
    const foundActiveTab = tabs.find((t: ITab) => t.display && t.id === this.$route.query.tab);
    this.activeTab = foundActiveTab ? foundActiveTab.id : tabs[0].id;
  }

  @Watch('activeTab')
  watchActiveTab() {
    this.filterGroups = [];
    this.filterValues = {};
    this.filterValuesFromRoute = {};
  }

  @Watch('$route')
  watchRoute(newRoute: any, oldRoute: any) {
    if (newRoute.params.id !== oldRoute.params.id) {
      this.fetchDestination();
    }
  }

  created() {
    this.fetchDestination();
  }

  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.query.tab !== from.query.tab) {
      const tab = to.query.tab ? String(to.query.tab) : '';
      this.activeTab = tab ? tab : this.tabTypes.details;
    } else if (!this.isRouteUpdatedByFilter) {
      this.filterValuesFromRoute = to.query;
    }
    this.isRouteUpdatedByFilter = false;
    next();
  }

  fetchDestination() {
    const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

    this.destinationFetching = true;

    this.$apollo
      .query({
        fetchPolicy: 'no-cache',
        query: destinationQuery,
        variables,
      })
      .then(({ data }: { data: any }) => {
        const destination = data?.destination || null;

        if (variables.onlyId && destination?.id) {
          this.$router.replace({
            ...redirectToSingleEntityRoute(String(this.$route.name), destination.id, variables.slug),
            query: this.$route.query,
          });
          return;
        }

        if (destination) {
          this.destination = destination;
          this.relatedItems = [...this.destination.artTrips, ...this.destination.stories];
        }
        this.destinationFetching = false;
      })
      .catch(() => {
        this.destinationFetching = false;
      });
  }

  selectTab(tab: ITab) {
    this.activeTab = tab.id;
    const query = {
      tab: this.activeTab !== this.tabTypes.details ? this.activeTab : undefined,
    };
    this.$router.push({ name: String(this.$route.name), params: this.$route.params, query });
  }

  handleOpenFilter() {
    this.$store.dispatch('toggleAppDataFilter', true);
  }

  handleCloseFilter() {
    this.$store.dispatch('toggleAppDataFilter', false);
  }

  handleSetFilter({ groups = [], values = null }) {
    if (groups.length) {
      this.filterGroups = groups;
    }
    if (values !== null) {
      this.filterValues = values;
    }
  }

  handleUpdateRouteAfterFilter(query = {}) {
    query = { tab: this.$route.query.tab, ...query };

    if (areRouteQueriesDifferent(this.$route.query, query)) {
      this.isRouteUpdatedByFilter = true;
      this.$router.push({ name: String(this.$route.name), params: this.$route.params, query });
    }
  }
}
