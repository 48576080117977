<template>
  <div class="data-details__tabs">
    <div class="data-details__tabs-box">
      <swiper
        ref="tabSlider"
        class="data-details__tabs-inner"
        :class="{ 'data-details__tabs-inner--tabs-4': tabs.length === 4 }"
        :options="swiperOptions"
      >
        <swiper-slide v-for="(tab, index) in tabs" :key="tab.id">
          <button class="btn" :class="{ active: activeTab === tab.id }" type="button" @click="handleSelect(tab, index)">
            {{ tab.title }}
          </button>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import DestinationPageTabTypes from '@/models/destinationPageTabs/DestinationPageTabTypes.enum';

export default {
  name: 'DetailTabs',
  props: {
    activeTab: {
      type: String,
      default: DestinationPageTabTypes.details,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
      },
    };
  },
  computed: {
    tabSlider() {
      return this.$refs.tabSlider?.swiper;
    },
  },
  mounted() {
    const activeTabIndex = this.tabs.findIndex((t) => t.id === this.activeTab);
    this.tabSlider.slideTo(activeTabIndex);
  },
  methods: {
    handleSelect(tab, index) {
      if (tab.id !== this.activeTab) {
        this.tabSlider.slideTo(index);
        this.$emit('select', tab);
      }
    },
  },
};
</script>
