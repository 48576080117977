<template>
  <div class="msg">{{ messageText }}</div>
</template>

<script>
export default {
  name: 'MsgEntityNotFound',
  props: {
    message: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'data',
    },
  },
  computed: {
    messageText() {
      return this.message ? this.message : `The ${this.name} has not been created or published yet.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.msg {
  padding-top: 50px;
  color: #363636;
  font-size: 16px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 24px;
  }
}
</style>
